import { Table, Text, Stack, Paper, Container, useMantineTheme } from '@mantine/core';

type ComparisonRow = {
  platform: string;
  totalCost: string;
  costBreakdown: string;
  savings?: string;
  isHighlighted?: boolean;
};

const comparisonData: ComparisonRow[] = [
  {
    platform: 'DeepCast Pro',
    totalCost: '$280',
    costBreakdown: "$265 annual plan (incl. 50 catalog backfill)\n+ $15 for 150 addt'l ($10 per 100)",
    savings: 'Save up to 95%',
    isHighlighted: true,
  },
  {
    platform: 'Ausha',
    totalCost: '$828',
    costBreakdown: '$69/mo. x 12 months (Supersonic plan, limited to 240 min./mo. transcription)',
  },
  {
    platform: 'Castmagic',
    totalCost: '$1,350',
    costBreakdown: '$0.15/min. rate at average duration*',
  },
  {
    platform: 'PodcastAI',
    totalCost: '$2,388',
    costBreakdown: '$199/mo. x 12 months',
  },
];

export default function ComparisonTable() {
  const theme = useMantineTheme();

  return (
    <Container size="lg" px={0} maw={900}>
      <Paper radius="lg" p="xl" bg={theme.other.colors.deepDark}>
        <Stack gap="md">
          <Table verticalSpacing="md" layout="fixed">
            <Table.Thead>
              <Table.Tr>
                <Table.Th w="25%">
                  <Text c="white" size="lg" ff={theme.headings.fontFamily}>
                    Platform
                  </Text>
                </Table.Th>
                <Table.Th w="25%">
                  <Text c="white" size="lg" ff={theme.headings.fontFamily}>
                    Total Cost
                  </Text>
                </Table.Th>
                <Table.Th w="55%" visibleFrom="sm">
                  <Text c="white" size="lg" ff={theme.headings.fontFamily}>
                    Cost Breakdown
                  </Text>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {comparisonData.map((row) => (
                <Table.Tr key={row.platform}>
                  <Table.Td>
                    <Stack gap={0}>
                      <Text
                        c={row.isHighlighted ? theme.other.brandColors.magenta : 'white'}
                        fw={row.isHighlighted ? 700 : 500}
                      >
                        {row.platform}
                      </Text>
                      {row.savings && (
                        <Text
                          size="xs"
                          c={row.isHighlighted ? theme.other.brandColors.magenta : theme.other.brandColors.cyan}
                        >
                          {row.savings}
                        </Text>
                      )}
                    </Stack>
                  </Table.Td>
                  <Table.Td>
                    <Text
                      c={row.isHighlighted ? theme.other.brandColors.magenta : 'white'}
                      fw={row.isHighlighted ? 700 : 500}
                    >
                      {row.totalCost}
                    </Text>
                  </Table.Td>
                  <Table.Td visibleFrom="sm">
                    <Text c="white" size="sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                      {row.costBreakdown}
                    </Text>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>

          <Text size="xs" mt="sm">
            *Based on processing 200 episodes (avg. 45 min length) with transcription and insights. Annual/monthly
            subscription costs included where applicable. Some plans have monthly processing limits.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
}
